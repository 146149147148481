import React, { useState } from 'react'
import './style.css'

const Gallery = () => {
  const [isColorful, setIsColorful] = useState(false)

  const [imageColor, setImageColor] = useState({})

  // Обработчик клика для изменения цветности фотографии
  const toggleColorMode = (index) => {
    // Копируем текущее состояние цветности
    const updatedImageColor = { ...imageColor }
    // Инвертируем цветность для данной фотографии по индексу
    updatedImageColor[index] = !updatedImageColor[index]
    // Обновляем состояние
    setImageColor(updatedImageColor)
  }
  return (
    <div className="gallery ">
      <div className="first__block">
        <img
          className={`first__block-item ${
            imageColor[0] ? 'colorful' : 'gallery-item'
          }`}
          src={require('../../assets/img/photo-6.jpg')}
          alt=""
          onClick={() => toggleColorMode(0)}
        />
        <img
          className={`first__block-item ${
            imageColor[1] ? 'colorful' : 'gallery-item'
          }`}
          src={require('../../assets/img/photo-9.jpg')}
          alt=""
          onClick={() => toggleColorMode(1)}
        />
        <img
          className={`first__block-item ${
            imageColor[2] ? 'colorful' : 'gallery-item'
          }`}
          src={require('../../assets/img/photo-10.jpg')}
          alt=""
          onClick={() => toggleColorMode(2)}
        />
        <img
          className={`first__block-item ${
            imageColor[3] ? 'colorful' : 'gallery-item'
          }`}
          src={require('../../assets/img/photo-15.jpg')}
          alt=""
          onClick={() => toggleColorMode(3)}
        />
      </div>

      {/* Второй блок с изображениями */}
      <div className="second__block">
        <img
          className={`second__block-item ${
            imageColor[4] ? 'colorful' : 'gallery-item'
          }`}
          src={require('../../assets/img/photo-14.jpg')}
          alt=""
          onClick={() => toggleColorMode(4)}
        />
        <img
          className={`second__block-item ${
            imageColor[5] ? 'colorful' : 'gallery-item'
          }`}
          src={require('../../assets/img/photo-5.jpg')}
          alt=""
          onClick={() => toggleColorMode(5)}
        />
      </div>

      {/* Третий блок с изображениями */}
      <div className="third__block">
        <img
          className={`third__block-item ${
            imageColor[6] ? 'colorful' : 'gallery-item'
          }`}
          src={require('../../assets/img/photo-1.jpg')}
          alt=""
          onClick={() => toggleColorMode(6)}
        />
        <img
          className={`third__block-item ${
            imageColor[7] ? 'colorful' : 'gallery-item'
          }`}
          src={require('../../assets/img/photo-3.jpg')}
          alt=""
          onClick={() => toggleColorMode(7)}
        />
        <img
          className={`third__block-item ${
            imageColor[8] ? 'colorful' : 'gallery-item'
          }`}
          src={require('../../assets/img/photo-4.jpg')}
          alt=""
          onClick={() => toggleColorMode(8)}
        />
        <img
          className={`third__block-item ${
            imageColor[9] ? 'colorful' : 'gallery-item'
          }`}
          src={require('../../assets/img/photo-11.jpg')}
          alt=""
          onClick={() => toggleColorMode(9)}
        />
      </div>
      <div className="second__block">
        <img
          className={`second__block-item ${
            imageColor[10] ? 'colorful' : 'gallery-item'
          }`}
          src={require('../../assets/img/photo-2.jpg')}
          alt=""
          onClick={() => toggleColorMode(10)}
        />
        <img
          className={`second__block-item ${
            imageColor[11] ? 'colorful' : 'gallery-item'
          }`}
          src={require('../../assets/img/photo_8.jpg')}
          alt=""
          onClick={() => toggleColorMode(11)}
        />
      </div>
      <div className="third__block">
        <img
          className={`third__block-item ${
            imageColor[12] ? 'colorful' : 'gallery-item'
          }`}
          src={require('../../assets/img/photo-20.jpg')}
          alt=""
          onClick={() => toggleColorMode(12)}
        />
        <img
          className={`third__block-item ${
            imageColor[13] ? 'colorful' : 'gallery-item'
          }`}
          src={require('../../assets/img/photo-17.jpg')}
          alt=""
          onClick={() => toggleColorMode(13)}
        />
        <img
          className={`third__block-item ${
            imageColor[14] ? 'colorful' : 'gallery-item'
          }`}
          src={require('../../assets/img/photo-18.jpg')}
          alt=""
          onClick={() => toggleColorMode(14)}
        />
        <img
          className={`third__block-item ${
            imageColor[15] ? 'colorful' : 'gallery-item'
          }`}
          src={require('../../assets/img/photo-19.jpg')}
          alt=""
          onClick={() => toggleColorMode(15)}
        />
      </div>
      {/* <div className="first__block">
        <img
          className="first__block-item"
          src={require('../../assets/img/photo-10.jpg')}
          alt=""
        />
        <img
          className="first__block-item"
          src={require('../../assets/img/photo-15.jpg')}
          alt=""
        />
      </div>
      <div className="second__block">
        <img
          className="second__block-item"
          src={require('../../assets/img/photo-5.jpg')}
          alt=""
        />
      </div>
      <div className="first__block">
        <img
          className="third__block-item"
          src={require('../../assets/img/photo-4.jpg')}
          alt=""
        />
        <img
          className="third__block-item"
          src={require('../../assets/img/photo-11.jpg')}
          alt=""
        />
      </div>
      <div className="second__block">
        <img
          className="second__block-item"
          src={require('../../assets/img/photo_8.jpg')}
          alt=""
        />
      </div>
      <div className="first__block">
        <img
          className="third__block-item"
          src={require('../../assets/img/photo-18.jpg')}
          alt=""
        />
        <img
          className="third__block-item"
          src={require('../../assets/img/photo-19.jpg')}
          alt=""
        />
      </div> */}
    </div>
  )
}

export default Gallery
