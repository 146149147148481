import React from 'react'
import './style.css'

const Header = () => {
  return (
    <div id="Header" className="header">
      <div className="container">
        <div className="header__inner">
          <div className="header__inner-item">
            <h1>Гилим Аружан Азаматқызы</h1>
            <h2>2001-2023</h2>
          </div>
          <img
            className="header__img"
            src={require('../../assets/img/header-img.jpg')}
            alt="header-photo"
          />
        </div>
      </div>
    </div>
  )
}

export default Header
