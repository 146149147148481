// import React, { useState, useEffect } from 'react'
// import ReactPlayer from 'react-player'
// import video1 from '../../assets/vidio/video_1.mp4'
// import video2 from '../../assets/vidio/video_2.mp4'
// import video3 from '../../assets/vidio/video_3.mp4'
// import video4 from '../../assets/vidio/video_4.mp4'
// import video5 from '../../assets/vidio/video_5.mp4'
// import video6 from '../../assets/vidio/video_6.mp4'
// import video7 from '../../assets/vidio/video_7.mp4'
// import './style.css'

// const videoUrls = [video1, video2, video3, video4, video5, video6, video7]

// const Slider = () => {
//   const [currentVideoIndex, setCurrentVideoIndex] = useState(0)

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setCurrentVideoIndex((prevIndex) =>
//         prevIndex === videoUrls.length - 1 ? 0 : prevIndex + 1
//       )
//     }, 5000) // Интервал смены видео в миллисекундах (здесь 5 секунд)

//     return () => clearInterval(interval)
//   }, [])

//   const handleVideoEnd = () => {
//     setCurrentVideoIndex((prevIndex) =>
//       prevIndex === videoUrls.length - 1 ? 0 : prevIndex + 1
//     )
//   }

//   return (
//     <div className="slider-container">
//       <ReactPlayer
//         url={videoUrls[currentVideoIndex]}
//         controls
//         playing
//         width="100%"
//         height="auto"
//         onEnded={handleVideoEnd} // Добавляем обработчик для окончания воспроизведения
//       />
//     </div>
//   )
// }

// export default Slider\

import React, { useState, useEffect, useRef } from 'react'
import video1 from '../../assets/vidio/video_1.mp4'
import video2 from '../../assets/vidio/video_2.mp4'
import video3 from '../../assets/vidio/video_3.mp4'
import video4 from '../../assets/vidio/video_4.mp4'
import video5 from '../../assets/vidio/video_5.mp4'
import video6 from '../../assets/vidio/video_6.mp4'
import video7 from '../../assets/vidio/video_7.mp4'
import './style.css'

const videoUrls = [video1, video2, video3, video5, video6, video7]

const Slider = () => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0)
  const videoRef = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)

  useEffect(() => {
    const interval = setInterval(() => {
      if (videoRef.current && videoRef.current.ended) {
        setCurrentVideoIndex((prevIndex) =>
          prevIndex === videoUrls.length - 1 ? 0 : prevIndex + 1
        )
        setIsPlaying(true)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [currentVideoIndex])

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
  }, [isPlaying])

  const handleVideoEnded = () => {
    setIsPlaying(false)
  }

  const handlePageClick = () => {
    if (!isPlaying) {
      setIsPlaying(true)
    }
  }

  const videoStyle = {
    maxWidth: '100%',
    maxHeight: 'calc(100vh - 20px)',
    width: '100%',
    height: 'auto',
  }

  return (
    <div className="slider-container" onClick={handlePageClick}>
      <video
        ref={videoRef}
        src={videoUrls[currentVideoIndex]}
        controls
        onEnded={handleVideoEnded}
        style={videoStyle}
        autoPlay // Автоматически запускаем видео после клика на странице
      >
        Ваш браузер не поддерживает видео.
      </video>
    </div>
  )
}

export default Slider
