import './App.css'

import Header from './components/Header'
import Film from './components/MemorableFilm'
import Main from './components/Main'
import Poem from './components/Poem'
import Menu from './components/Menu'
import About from './components/About'

function App() {
  return (
    <div className="App">
      <Menu />
      <Header />
      <About />
      <Film />
      <Main />
      <Poem />
    </div>
  )
}

export default App
