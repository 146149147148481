import React from 'react'
import './style.css'
const Film = () => {
  return (
    <div id="Film" className="film">
      Film
    </div>
  )
}

export default Film
