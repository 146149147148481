import React from 'react'
import './style.css'
import Carousel from '../Carousel'
import Slider from '../Slider'
import Gallery from '../Gallery'

const Main = () => {
  return (
    <div id="Main" className="main">
      <div className="container">
        {/* <Carousel /> */}
        <Slider />
        <Gallery />
      </div>
    </div>
  )
}

export default Main
