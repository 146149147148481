import { useState } from 'react'
import './style.css'

const Menu = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  const toggleMenu = () => {
    setMenuOpen(!menuOpen)
  }

  const closeMenu = () => {
    // Закрывать меню можно, установив значение menuOpen в false
    setMenuOpen(false)
  }

  return (
    <div className="burger-menu">
      <div className="menu-header">
        <div
          className={`burger-icon ${menuOpen ? 'open' : ''}`}
          onClick={toggleMenu}
        >
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </div>
      <ul className={`menu ${menuOpen ? 'open' : ''}`}>
        <li className="nav-item">
          <a href="#Header" onClick={closeMenu}>
            Главная
          </a>
        </li>
        <li className="nav-item">
          <a href="#About" onClick={closeMenu}>
            Биография
          </a>
        </li>
        <li className="nav-item">
          <a href="#Film" onClick={closeMenu}>
            Фильм
          </a>
        </li>
        <li className="nav-item">
          <a href="#Main" onClick={closeMenu}>
            Галерея
          </a>
        </li>
        <li className="nav-item">
          <a href="#Poem" onClick={closeMenu}>
            Поэма
          </a>
        </li>
      </ul>
    </div>
  )
}

export default Menu
